<template>
  <div v-if="!edit">
    <div v-if="readingDetails">
      <h2>
        {{readingDetails.title}}
      </h2>
      <small class="text-muted">
        Class reading
      </small>
      <ckeditor :editor="editor" v-model="readingDetails.content"
        :config="editorConfig" readonly disabled>
      </ckeditor>
      <div class="text-end mt-5" v-if="userData.roleId == 2 && editable">
        <button class="btn btn-lg bg-pri text-end"
        @click="edit = true">
          Edit content
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    <h2> Edit reading content </h2>
    <input class="form-control" type="text"
    placeholder="Reading Title" v-model="readingDetails.title">
    <ckeditor :editor="editor" v-model="readingDetails.content"
      :config="editorConfig">
    </ckeditor>
    <div class="text-end mt-5">
      <button class="btn btn-lg text-end" @click="edit = false">
        Cancel
      </button>
      <button class="btn btn-lg bg-pri text-end" @click="saveReading">
        Save changes
      </button>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import InlineEditor from '../ckeditor';

export default {
  name: 'ModuleReadingConfig',
  props: ['contentId', 'item', 'editable'],
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  watch: {
    contentId(newVal) {
      if (newVal) {
        this.getReadingDetails();
      }
    },
  },
  data() {
    return {
      readingDetails: {
        id: null,
        title: '',
        content: '',
      },
      startTime: null,
      timeSpent: 0,
      edit: false,
      courseId: null,
      selectedAss: null,
      editor: InlineEditor,
      readSpeed: 200,
      editorConfig: {
        isReadOnly: 'true',
        image: {
          // Configure the available styles.
          styles: [
            'full', 'alignLeft', 'alignCenter', 'alignRight',
          ],

          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
            'imageStyle:full',
            'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
            '|',
            'imageTextAlternative',
          ],
        },
      },
      // mode: null,
      // code: null,
    };
  },
  methods: {
    getReadingDetails() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/module/getreading', {
        params: {
          aId: this.contentId,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          this.readingDetails = response.data.readingDetails;
          this.$store.commit('setLoadScreen', { status: false });
        }).catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    saveReading() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.post('/api/module/savereading', {
        role: this.userData.roleId,
        reading: this.readingDetails,
      })
        .then((response) => {
          this.$store.commit('setLoadScreen', { status: false });
          this.item.contentId = response.data.readingId;
          this.edit = false;
          this.$parent.saveChanges();
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
  },
  mounted() {
    this.courseId = this.$route.params.cid;
    this.item.edit = false;
    this.startTime = Date.now();
    console.log(this.$parent.editable);
  },
  beforeMount() {
    console.log(this.contentId);
    if (this.contentId) {
      this.getReadingDetails();
    }
  },
  beforeDestroy() {
    this.timeSpent = Date.now() - this.startTime;
    const contentLength = this.readingDetails.content.split(' ').length;
    if (!this.item.completed && this.timeSpent > contentLength * this.readSpeed
      && this.userData.roleId === 1) {
      axios.post('/api/module/complete', {
        contentId: this.item.id,
        studentId: this.userData.id,
      })
        .then(() => { this.$set(this.item, 'completed', true); })
        .catch((err) => { console.log(err); });
    }
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
.content-box {
  background-color: rgba(251, 240, 218, 0.3);
  padding: 1rem 0;
}
.add-content {
  border:  dashed #ddd 2px;
  height: 5rem;
  margin: 1rem 2rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1rem;
}
.add-content i {
  font-size: 2rem;
  color:  var(--dark-color);
}
.content-list {
  list-style:  none;
  padding: 0;
  margin:  0;
}
.content-item {
  padding: 0.1rem 0.5rem;
  border-left: 4px transparent solid;
  cursor: pointer;
}
.content-item.active {
  border-left: 4px var(--accent-1) solid;
  background-color: rgba(0,0,0,0.05);
}
.ck-content {
  margin-top: 3rem;
  min-height: 10rem;
  background-color: var(--accent-1-tint-1);
}
</style>

<template>
  <div>
    <button class="btn mb-2 text-primary" @click="$parent.selectedModule = null"> Go back </button>
    <div class="row mt-5">
      <div class="col-md-3">
        <div class="row m-0" v-show="editable">
          <div class="col add-content me-3 dropdown">
            <div class="row h-100 hover-pointer" data-bs-toggle="dropdown">
              <div class="col-4 d-flex align-items-center">
                <i class="material-icons align-middle"> collections_bookmark </i>
              </div>
              <div class="col-8 d-flex align-items-center text-start">
                <small>Add materials</small>
              </div>
            </div>
            <ul class="dropdown-menu">
              <li>
                <button class="dropdown-item" type="button"
                @click="addContent('text')">Text/video</button>
              </li>
              <li>
                <button class="dropdown-item" type="button"
                @click="addContent('file')">Upload a file</button>
              </li>
            </ul>
          </div>
          <div class="col add-content hover-pointer d-flex align-items-center"
          @click="addContent('assignment')">
            <div class="row h-100">
              <div class="col-4 d-flex align-items-center">
                <i class="material-icons align-middle"> task </i>
              </div>
              <div class="col-8 d-flex align-items-center text-start">
                <small>Add assignment</small>
              </div>
            </div>
          </div>
        </div>
        <div class="content-sidebar">
          <ul class="content-list">
            <li class="content-item" :class="{active:
            (activeItem.id !== null && item.id !== null) ?
            activeItem.id === item.id
            : activeItem.seq === item.seq }"
            @click="setActive(item)"
            v-for="(item, index) in content" :key="index">
            <button class="btn float-end" v-show="activeItem.seq == item.seq && editable"
            @click="deleteItem(index)">
              <i class="material-icons text-muted" style="font-size: 1rem;">close</i>
            </button>
              <div v-if="item.type == 1">
                <div>
                   <p>
                    <b class="d-block"> Assessment: </b>
                    <span v-if="item.title">
                      {{item.title}}
                    </span>
                    <em v-else>
                      Click to configure
                    </em>
                  </p>
                </div>
              </div>
              <!-- TODO: fix this hard code type -->
              <div v-else-if="item.type === 2 || item.type === 4">
                <div>
                   <p>
                     <b class="d-block" v-if="item.type === 2"> File: </b>
                     <b class="d-block" v-else-if="item.type === 4"> Lesson: </b>
                    <span v-if="item.title">
                      {{item.title}}
                    </span>
                    <em v-else>
                      Click to configure
                    </em>
                  </p>
                </div>
              </div>
              <div v-else-if="item.type == 3">
                <div>
                   <p>
                    <b class="d-block"> Reading: </b>
                    <span v-if="item.title">
                      {{item.title}}
                    </span>
                    <em v-else>
                      Click to configure
                    </em>
                  </p>
                </div>
              </div>
              <div v-else> hasdaksdjas </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-9 content-viewer">
        <ModuleAssessmentConfig v-if="activeItem &&activeItem.type == 1"
        :content-id="activeItem.contentId" :item="activeItem" :key="activeItem.contentId"
        :editable="editable">
        </ModuleAssessmentConfig>
        <ModuleReadingConfig v-else-if="activeItem &&activeItem.type == 3"
        :content-id="activeItem.contentId" :item="activeItem" :key="activeItem.contentId"
        :editable="editable">
        </ModuleReadingConfig>
        <ModuleFileConfig v-else-if="activeItem && (activeItem.type === 2 || activeItem.type === 4)"
        :content-id="activeItem.contentId" :item="activeItem" :key="activeItem.contentId"
        :module-id="selected.id"
        :editable="editable">
        </ModuleFileConfig>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import ModuleAssessmentConfig from './ModuleAssessmentConfig.vue';
import ModuleReadingConfig from './ModuleReadingConfig.vue';
import ModuleFileConfig from './ModuleFileConfig.vue';

export default {
  name: 'CourseModuleDetails',
  props: ['selected'],
  components: {
    ModuleAssessmentConfig,
    ModuleReadingConfig,
    ModuleFileConfig,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      courseId: null,
      content: [],
      assObj: [],
      activeItem: null,
      editable: false,
      // mode: null,
      // code: null,
    };
  },
  methods: {
    getModuleDetails() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/module/details', {
        params: {
          courseId: this.courseId,
          seq: this.selected.seq,
        },
      })
        .then((response) => {
          this.content = response.data;
          [this.activeItem] = this.content;
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    setActive(item) {
      this.activeItem = item;
    },
    // getAssInfo(id) {
    //   return this.assObj.filter((ass) => ass.id === id)[0];
    // },
    // getReadingInfo(id) {
    //   return this.readObj.filter((read) => read.id === id)[0];
    // },
    getAss() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/assessment/course/${this.courseId}`, {
        params: {
          user: this.userData.id,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          this.assObj = response.data.assignmentList;
          this.$store.commit('setLoadScreen', { status: false });
        }).catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    addContent(type) {
      let item = {};
      switch (type) {
        case 'assignment':
          item = {
            id: null,
            type: 1,
            typeName: type,
            seq: this.content.length + 1,
            edit: true,

          };
          this.content.push(item);
          this.setActive(item);
          break;
        case 'text':
          item = {
            id: null,
            title: null,
            type: 3,
            typeName: type,
            seq: this.content.length + 1,
            edit: true,
          };
          this.content.push(item);
          this.setActive(item);
          break;
        case 'file':
          item = {
            id: null,
            title: null,
            type: 2,
            typeName: type,
            seq: this.content.length + 1,
            edit: true,
          };
          this.content.push(item);
          this.setActive(item);
          break;
        default:
          break;
      }
    },
    deleteItem(ind) {
      this.content.splice(ind, 1);
      this.saveChanges();
    },
    saveChanges() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.post('/api/module/saveitems', {
        role: this.userData.roleId,
        moduleId: this.selected.id,
        content: this.content,
      })
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
          this.getModuleDetails();
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    getCourseInfo() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/course/${this.courseId}`, {
        params: {
          role: this.userData.roleId,
          user: this.userData.id,
        },
      })
        .then((response) => {
          this.$store.commit('setLoadScreen', { status: false });
          this.editable = !response.data.published;
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
  },
  mounted() {
  },
  beforeMount() {
    this.courseId = this.$route.params.cid;
    this.getAss();
    this.getModuleDetails();
    this.getCourseInfo();
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
.content-box {
  /*background-color: rgba(251, 240, 218, 0.3);*/
  padding: 1rem 0;
}
.add-content {
  border:  dashed #ddd 2px;
  height: 5rem;
  /*margin: 1rem 2rem;*/
  margin-bottom: 0.5rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1rem;
}
.add-content i {
  font-size: 2rem;
  color:  var(--dark-color);
}
.content-list {
  list-style:  none;
  padding: 0;
  margin:  0;
  position: sticky;
}
.content-viewer {
  max-height: 90vh;
  padding-bottom: 2rem;
  overflow-y: auto;
}
.content-item {
  padding: 0.1rem 0.5rem;
  border-left: 4px transparent solid;
  cursor: pointer;
}
.content-item.active {
  border-left: 4px var(--accent-1) solid;
  background-color: rgba(0,0,0,0.05);
}
</style>

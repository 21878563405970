import { render, staticRenderFns } from "./ModuleAssessmentConfig.vue?vue&type=template&id=716ab73a&scoped=true&"
import script from "./ModuleAssessmentConfig.vue?vue&type=script&lang=js&"
export * from "./ModuleAssessmentConfig.vue?vue&type=script&lang=js&"
import style0 from "./ModuleAssessmentConfig.vue?vue&type=style&index=0&id=716ab73a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "716ab73a",
  null
  
)

export default component.exports
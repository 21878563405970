<template>
  <div  v-if="contentId">
    <div v-if="assDetails">
      <h2>
        {{assDetails.title}}
        <button class="btn btn-line-dark" @click="loadAssessment">
          <i class="material-icons align-middle"> launch </i>
        </button>
      </h2>
      <small class="text-muted">
        {{assDetails.aType.type}}
        {{assDetails.totTime ? `-${assDetails.totTime} mins` : ''}}
      </small>
      <div class="mt-5">
        <p> <b> Assignment Information: </b> </p>
        <div class="d-inline me-3 text-muted">
          <b> Number of Attempts: </b> {{assDetails.att}}
        </div>
        <div class="d-inline text-muted">
          <b> Total Points: </b> {{assDetails.totPoints}}
        </div>
      </div>
      <div class="text-end mt-5" v-show="editable">
        <button class="btn btn-lg bg-pri text-end" @click="item.contentId = null">
          Edit selection
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    <h2> Select an assignment </h2>
    <select v-model="selectedAss" class="form-select">
      <option v-for="ass in $parent.assObj" :key="ass.id" :value="ass.id">
        {{ass.title}}
      </option>
    </select>
    <div class="text-end mt-5">
      <button class="btn btn-lg bg-pri text-end" @click="saveSelection">
        Save changes
      </button>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'ModuleAssessmentView',
  props: ['contentId', 'item', 'editable'],
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  watch: {
    contentId(newVal) {
      if (newVal) {
        this.getAssDetails();
      }
    },
  },
  data() {
    return {
      assDetails: null,
      courseId: null,
      selectedAss: null,
      // mode: null,
      // code: null,
    };
  },
  methods: {
    getAssDetails() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/assessment/getassessmentinfo', {
        params: {
          aId: this.contentId,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          this.assDetails = response.data.assignmentInfo;
          console.log(this.assDetails);
          this.$store.commit('setLoadScreen', { status: false });
        }).catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    loadAssessment() {
      this.$router.push(`/edittask/${this.courseId}?aID=${this.contentId}`);
    },
    saveSelection() {
      this.item.contentId = this.selectedAss;
      this.item.edit = false;
      this.$parent.saveChanges();
    },
    getAss() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/assessment/course/${this.courseId}`, {
        params: {
          user: this.userData.id,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          this.assObj = response.data.assignmentList;
          this.$store.commit('setLoadScreen', { status: false });
        }).catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
  },
  mounted() {
    this.courseId = this.$route.params.cid;
  },
  beforeMount() {
    if (this.contentId) {
      this.getAssDetails();
    }
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
.content-box {
  background-color: rgba(251, 240, 218, 0.3);
  padding: 1rem 0;
}
.add-content {
  border:  dashed #ddd 2px;
  height: 5rem;
  margin: 1rem 2rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1rem;
}
.add-content i {
  font-size: 2rem;
  color:  var(--dark-color);
}
.content-list {
  list-style:  none;
  padding: 0;
  margin:  0;
}
.content-item {
  padding: 0.1rem 0.5rem;
  border-left: 4px transparent solid;
  cursor: pointer;
}
.content-item.active {
  border-left: 4px var(--accent-1) solid;
  background-color: rgba(0,0,0,0.05);
}
</style>

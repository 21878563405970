<template>
  <div class="document-reader" style="height: 600px;">
    <div class="h-100" v-if="documentType === 'pdf'">
      <div class="document-reader-header">
        <template v-if="isLoading">
          Loading...
        </template>

        <template v-else>
      <span v-if="showAllPages">
        {{ pageCount }} page(s)
      </span>
          <span v-else>
        <button  class="btn btn-outline-light" :disabled="page <= 1" @click="page--">❮</button>
        <span class="mx-1 mt-3">{{ page }} / {{ pageCount }}</span>
        <button class="btn btn-outline-light"  :disabled="page >= pageCount"  @click="page++">❯
        </button>
      </span>

          <label class="right">
            <input v-model="showAllPages" type="checkbox">
            Show all pages
          </label>
        </template>
      </div>
      <div class="document-reader-content">
        <vue-pdf-embed
          ref="pdfRef"
          :source="documentLink"
          :page="page"
          @password-requested="handlePasswordRequest"
          @rendered="handleDocumentRender"
        />
      </div>
    </div >
    <div class="h-100" v-else-if="documentType === 'pptx'">
      <div class="document-reader-content">
        <iframe  height="100%" width="100%" :src="PPTXDocumentLink" title="description" />
      </div>
    </div>
  </div>

</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
  name: 'ModuleDocumentViewer',
  components: {
    VuePdfEmbed,
  },
  props: {
    documentLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documentType: '',
      PPTXDocumentLink: '',
      isLoading: true,
      page: null,
      pageCount: 1,
      showAllPages: true,
    };
  },
  computed: {
    fileType() {
      const filesParts = this.documentLink.split('.');
      return filesParts[filesParts.length - 1];
    },
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
    documentLink: {
      handler() {
        this.documentType = this.fileType.toLowerCase();
        if (this.documentType === 'pptx') {
          this.PPTXDocumentLink = `https://view.officeapps.live.com/op/view.aspx?src=${this.documentLink}`;
        }
      },
      deep: true,
    },
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    handlePasswordRequest(callback, retry) {
      callback(prompt(retry
        ? 'Enter password again'
        : 'Enter password'));
    },
  },
};
</script>

<style lang="css" scoped>
.document-reader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.document-reader > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.document-reader-header {
  padding: 12px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color);
  color: #ddd;
}

.document-reader-content {
  padding: 24px 16px;
  height: 100%;
  width:100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.right {
  float: right;
}

</style>

<template>
  <div  v-if="!edit && item.contentId">
    <h2> {{item.title}} </h2>
    <div class="mt-4">
      <button class="btn bg-pri me-4" @click="downloadFile"> Download </button>
      <button class="btn bg-sec" @click="deleteFile"
      v-if="userData.roleId == 2 && editable"> Change File </button>
    </div>
    <div class="mt-4">
      <ModuleVideoViewer v-if="isVideo"
        :videoLink="resourceLink"
      />
      <ModuleDocumentViewer  v-else-if="isDocument"
                             :documentLink="resourceLink"
      />
    </div>
  </div>
  <div v-else>
    <h2> Upload a file </h2>
    <div class="upload-box" @drop="uploadFile">
      <input name="mod-file" id="mod-file" class="d-none" type="file"
      accept=".doc, .docx, .pdf, .pptx, .xlsx, .csv, .ppt, .xls, video/*"
      @change="uploadFile">
      <i class="material-icons d-block" style="font-size: 5rem;"> upload </i>
      <label for="mod-file" type="button">
        <b> Choose a file </b> or drag it here
      </label>
    </div>
    <div class="lesson-checkbox" v-if="userData.roleId === 2">
      <input id="lesson-checkbox-upload" class="form-check-input" type="checkbox"
             v-model="isLesson"
      >
      <label for="lesson-checkbox-upload" class="form-check-label">
        <strong>Mark this as lesson</strong>
      </label>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import ModuleVideoViewer from '@/components/ModuleVideoViewer.vue';
import ModuleDocumentViewer from '@/components/ModuleDocumentViewer.vue';

export default {
  name: 'ModuleFileView',
  props: ['contentId', 'item', 'editable', 'moduleId'],
  components: {
    ModuleVideoViewer,
    ModuleDocumentViewer,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    isVideo() {
      const videoFormats = ['flv', 'avi', 'mp4', '3gp', 'mov', 'webm'];
      const videoParts = this.item.title.split('.');

      return videoFormats.includes(videoParts[videoParts.length - 1]);
    },
    isDocument() {
      const videoParts = this.item.title.split('.');
      return videoParts[videoParts.length - 1] === 'pdf' || videoParts[videoParts.length - 1] === 'pptx';
    },
    isLesson: {
      get() {
        return this.item.type === 4;
      },
      set() {
        this.markAsLesson();
      },
    },
  },
  data() {
    return {
      courseId: null,
      title: 'Untitled File',
      fId: null,
      edit: false,
      resourceLink: '',
      // mode: null,
      // code: null,
    };
  },
  methods: {
    markAsLesson() {
      if (this.isLesson) {
        this.$set(this.item, 'type', 2);
      } else {
        this.$set(this.item, 'type', 4);
      }
    },
    uploadFile(e) {
      const file = e.target.files[0];

      // const reader = new FileReader();
      // reader.readAsDataURL(image);
      // reader.onload = e =>{
      //     this.qInfo['image'] = e.target.result;
      // };

      const formData = new FormData();
      formData.append('file', file);
      this.title = file.name;
      formData.append('id', this.userData.id);
      formData.append('title', this.title);
      axios.post('/api/module/uploadfile', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.item.contentId = response.data.fileId;
          this.$parent.saveChanges();
          // console.log(`${process.env.VUE_APP_API_URL}${response.data.url}`);
          // this.fileName = response.data.fileName;
        });
    },
    deleteFile() {
      axios.delete(`/api/module/file/${this.item.contentId}`)
        .then(() => {
          this.item.id = null;
          this.item.contentId = null;
          this.item.title = 'Untitled File';
          this.edit = true;
        });
    },
    downloadFile() {
      axios.get('/api/module/filename', {
        params: {
          fId: this.item.contentId,
        },
      })
        .then((response) => {
          if (this.userData.roleId === 1) {
            axios.post('/api/module/complete', {
              contentId: this.item.id,
              studentId: this.userData.id,
            })
              .then(() => { this.$set(this.item, 'completed', true); })
              .catch((err) => { console.log(err); });
          }
          window.open(`${response.data.fileName}`, '_blank').focus();
        });
    },
    getResourceLink(contentId) {
      if (contentId) {
        axios.get('/api/module/filename', {
          params: {
            fId: contentId,
          },
        })
          .then((response) => {
            if (this.userData.roleId === 1) {
              axios.post('/api/module/complete', {
                contentId: this.item.id,
                studentId: this.userData.id,
              })
                .then(() => { this.$set(this.item, 'completed', true); })
                .catch((err) => { console.log(err); });
            }

            this.resourceLink = response.data.fileName;
          });
      }
    },
    updateResourceType(moduleId, contentId, contentType) {
      if (moduleId && contentId && contentType) {
        axios
          .put(`/api/module/${moduleId}/content/${contentId}`, { type: contentType })
          .then(() => {
            this.$set(this.item, 'type', contentType);
          })
          .catch((err) => { console.error(err); });
      }
    },
  },
  watch: {
    contentId: {
      handler(newVal) {
        this.getResourceLink(newVal);
      },
      deep: true,
    },
    isLesson: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // TODO: Fix this hard code 4 = Lesson, 2 = File
          const newType = newVal ? 4 : 2;
          if (newVal) {
            this.$set(this.item, 'type', newType);
          } else {
            this.$set(this.item, 'type', newType);
          }
          if (this.item.contentId) {
            this.updateResourceType(this.moduleId, this.item.contentId, newVal);
          }
        }
      },
    },
  },
  mounted() {
    this.courseId = this.$route.params.cid;
    this.getResourceLink(this.contentId);
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
.content-box {
  background-color: rgba(251, 240, 218, 0.3);
  padding: 1rem 0;
}
.upload-box {
  border:  dashed #ddd 2px;
  min-height: 5rem;
  padding:  5rem 0;
  vertical-align: middle;
  text-align: center;
  font-size: 1rem;
}
.add-content i {
  font-size: 2rem;
  color:  var(--dark-color);
}
.content-list {
  list-style:  none;
  padding: 0;
  margin:  0;
}
.content-item {
  padding: 0.1rem 0.5rem;
  border-left: 4px transparent solid;
  cursor: pointer;
}
.content-item.active {
  border-left: 4px var(--accent-1) solid;
  background-color: rgba(0,0,0,0.05);
}
div.lesson-checkbox {
  margin-top: 1rem;
}
div.lesson-checkbox > label {
  padding-left: 0.75rem;
}
</style>

<template>
  <div class="border border-1">
    <vue-player :src="videoPreviewLink" :controls=true />
  </div>
</template>
<script>
import vuePlayer from '@algoz098/vue-player';

export default {
  name: 'ModuleVideoViewer',
  components: {
    vuePlayer,
  },
  data() {
    return {
      instance: null,
      option: {
        autoSize: true,
      },
    };
  },
  computed: {
    videoPreviewLink() {
      return this.videoLink;
    },
  },
  props: {
    videoLink: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
</style>
